/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
}

/* nomal */
body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-blue {
  background-color: blue;
}

.bg-gray {
  background: #f5f6f9;
;
}

/* custom */
:root {
  --header-height: 105px;
  --header-mobile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
}

:root {
  --page-width: 1400px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}

.block  {
  display: block !important;
}

.none {
  display: none !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
    font-weight: bold !important;
}

/* ======================================================== */
/* Header  */

.header {
  height: 85px;
  /* 평상시에 height를 초과한 서브메뉴가 안보이게 hidden */
  overflow: hidden;
  background: rgba(100,100,100,.1);
  border-bottom: 1px solid rgba(255,255,255,0.3);
  position: fixed;
  width: 100%;
  z-index: 500;
  transition: all .4s;
}

.header-changed {
  background: rgba(30,30,30,.9); 
}

.header:hover {
  height: 300px;
  background: rgba(30,30,30,.9); 
}

.header__con {
  height: 85px;
  max-width: var(--page-width);
  width: 100%;
  margin: 0 auto;
  padding: var(--page-padding-mo);
  display: flex;
  justify-content: space-between;
  color: white;
  z-index: 300 !important;
}

.header__logo-con {
  height: 100%;
  display: flex;
}

.header__logo-con > li {
  height: 100%; 
  font-size: 27px;
  font-weight: 800;
  color: #fff;
  line-height: 28px;

}

.header__logo-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo-con > li:first-child > a > img {
  height: 24px;
}


.header__category-con {
  height: 100%;
  display: flex;
}

.header__category-con > li {
  height: 100%;
  width: 130px;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

.header__category-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__hamburger {
  display: none;
}



/* .header__con:hover,  .header__category-con > li > a {
  border-bottom: 2px solid #fff;
} */

.header__category-con:hover > li > a {
  border-bottom: 2px solid #fff;
}

.header__category-sub-con {
  padding-top: 10px;
}

.header__category-sub-con a {
  display: block;
  text-align: center;
  font-size: 15px;
  color: #cdcccc;  
  line-height: 35px;
  font-weight: 600;
  transition: all .1s;
}

.header__category-sub-con a:hover {
  color: #fff;
  background-color: black;
  border-radius: 10px;
}

.header__icon-con {
  border-left: 1px solid rgba(255,255,255,0.2);
  padding-left: 40px;
  display: flex;
  align-items: center;

  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 11px;
  font-weight: 500;

}

.header__icon-con li {
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header__icon-con li:not(:nth-last-child(1)) {
  border-right: 0.1px solid #fff;
}


@media (max-width: 1023px) {
  .header-unclick {
    height: 60px !important;
  }

  .header-click {
    height: 90% !important;
  }

  .header__con {
    height: 60px;
    display: block;
  }

  .header__logo-con {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header__logo > a {
    font-size: 22px;
  }

  .header__logo-con > li:first-child > a > img {
    height: 18px;
  }

  .header__hamburger {
    display: block;
  }


  .header__category-con {
    height: auto; /* 세로 길이만큼 높이를 설정  */
    margin-left: 0px;
    flex-direction: column;
  }

  .header__category-con > li {
    width: 100%;
    font-size: 17px;
  }

  .header__category-con:hover > li > a,
  .header__category-con > li > a {
    padding: 12px 0;
    border-bottom: 1px solid #707070;
    justify-content: flex-start;

  }

  .header__category-con > li > a::before {
    content: '+';
    padding: 0 7px;
    margin-right: 20px;
    font-size: 15px;
    font-weight: 300;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius: 50%;
  }

  .header__category-sub-con {
    display: none;
  }

  .header__category-sub-con a {
    font-size: 15px;
  }

  .header__icon-con {
    margin-top: 30px;
    font-size: 12px;
    color: #cdcccc;
  }

}

/* ======================================================== */

.intro-spot {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.intro-spot__con {
  position:relative;
  width: 100%;
  height: 100%;
}

.intro-spot__bg {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
}

.video-bigger-than-web { /* 영상 가로가 웹보다 큰 경우 (웹의 가로가 16:9 비율보다 작음) */
  position: absolute;
  left: calc(-1 * ((100vh * 16 / 9) - 100vw) / 2); /* 3. (영상가로 - 웹가로)의 절반만큼 왼쪽이동 */
  width: calc((100vh * 16 / 9)); /* 2. 영상 가로는 1의 16:9 비율이 되게   */
  height: 100vh; /* 1. 영상의 세로를 웹 세로에 맞추고  */
  animation-name: fadein;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  opacity: 1; /* 애니메이션 후 최종 투명도 */
  overflow: hidden;
}

.video-smaller-than-web { /* 영상 가로가 웹보다 작은 경우 (웹의 가로가 16:9보다 큼) */
  position: absolute;
  top: calc(-1 * ((100vw * 9 / 16) - 100vh) / 2);
  width: 100vw;
  height: calc((100vw * 9 / 16));
  animation-name: fadein;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  opacity: 1; /* 애니메이션 후 최종 투명도 */
  overflow: hidden;
}


@keyframes fadein{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


.intro-spot__bg-dot{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.intro-spot__txt-con {
  position: absolute;
  top: 50%;
  left: 50%;
  /* hw. 헤더 크기를 제외한 지역에서 중앙에 위치하게 변경 */
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 200;
}

.intro-spot__txt-con > img {
  height: 100px;
}

.intro-spot__txt-title {
  margin: 20px 0;
  font-size: 65px;
  line-height: 65px;
  font-weight: 800;
  color: #efeeee;
  /* letter-spacing: -1px; */
  opacity: 1;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
}

.intro-spot__txt-sub {
  font-size: 20px;
  font-weight: 500;
  color: #efeeee;
  opacity: 0.9;
}

.intro-spot__arrow {
  position: absolute;
  left: 50%;
  bottom: 40px;
  translate: -50%;
  z-index: 100;
}

.intro-spot__arrow > img {  
  animation: arrowdown .7s ease-in-out infinite alternate;
}

@keyframes arrowdown{
  from{
    opacity: 0.2;
  }
  to{
    opacity: 0.5;
    transform: translateY(10px);
  }
}

@media (max-width: 1023px) {
  .intro-spot {
    height: 100vh;
  }
  .intro-spot__txt-con {
    top: 48%;
  }

  .intro-spot__txt-con > img {
    height: 60px;
  }
  

  .intro-spot__txt-title {
    margin: 10px 0;
    font-size: 41px;
    line-height: 45px;
    letter-spacing: -1.5px;
  }

  .intro-spot__txt-sub {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
}


/* ======================================= */

.intro-brand {
  padding: 150px 0;

  /* 배경 고정 */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.intro-brand__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
  display: flex;
  justify-content: space-between;
}

.intro-brand__txt-title {
  margin:0;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  color: #efeeee;
  text-shadow: 1px 1px 1px #808080;
}

.intro-brand__txt-sub {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #efeeee;
  /* text-shadow: 1px 1px 1px #808080; */
}

.intro-brand__btn-wrap {
  display: flex;
  align-items: center;
}

.intro-brand__btn-wrap > span {
  position: relative;
  padding: 10px 20px;  
  font-size: 18px;
  font-weight: 400;
  background-color: rgba(255,255,255,.3);
  color: #efeeee;
  border-radius: 2px;
  cursor: pointer;  
  /* text-shadow: 1px 1px 1px #808080; */
}

@media (max-width: 1023px) {
  .intro-brand {
    padding: 65px 0;
  }

  .intro-brand__con {
    padding: var(--page-padding-tab);
  }
  
  .intro-brand__txt-title {
    font-size: 28px;
  }
  
  .intro-brand__txt-sub {
    font-size: 15px;
  }

  .intro-brand__btn-wrap > span {
    padding: 10px 20px;  
    font-size: 18px;
  }
  
  
}
@media (max-width: 767px) {
  .intro-brand {
    background-position: 0px;
  }
  .intro-brand__con {
    padding: var(--page-padding-mo);
    flex-direction: column;
  }

  .intro-brand__txt-title,
  .intro-brand__txt-sub {
    text-align: center;
  }

  .intro-brand__btn-wrap {
    margin-top: 30px;
    justify-content: center;
  }



}
@media (max-width: 480px) {
}


/* ======================================= */
.intro-portfolio {
  padding: 100px 0;
}

.intro-portfolio__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
} 

.intro-portfolio__txt-title,
.intro-portfolio__txt-sub {
  /* hw. 폰트사이즈 넣으면 마진이 폰트크기만큼 상하로 발생? */
  margin:0;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #333;
}

.intro-portfolio__txt-sub {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 400;
}

.intro-portfolio__grid-con { 
  /* margin: 40px 0 40px -10px; */
  display: flex;
  flex-wrap: wrap;
}

.intro__grid-item {
  margin: 10px;
  width: calc( (100% - 3 * 20px) / 3 );
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.intro__img-box {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* border-radius: 10px; */
}

.intro__img-box::after {
  content: '';
  display: block;
  padding-bottom: 75%;
}

.intro__hover-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  color: #fff;
  transition: all .4s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro__hover-box > img {
  padding: 10px;
  width: 60px;
  object-fit: cover;
  border: 2px solid #d1d1d1;
  border-radius: 50%;
}

.intro__grid-item > a:hover > .intro__img-box + .intro__hover-box {
  opacity: .8;
}



.intro-portfolio__btn-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.intro-portfolio__btn-wrap > span {
  position: relative;
  padding: 10px 40px;  
  font-size: 18px;
  font-weight: 500;
  background: #383433;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;  
}

.txt-line {
  width: 40px;
  height: 3px;
  background: #383433;
  margin: 40px auto;
  text-align: center;
}

@media (max-width: 1023px) {
  .intro-portfolio {
    padding: 65px 0;
  }

  .intro-portfolio__con {
    padding: var(--page-padding-tab);
  } 

  .intro-portfolio__txt-sub {
    font-size: 18px;
  }

}
@media (max-width: 767px) {
  .intro-portfolio__con {
    padding: var(--page-padding-mo);
  }

  .intro__grid-item {
    margin: 0;
    margin-bottom: 15px;
    width: calc( (100% - 2 * 5px) / 2 );
  }

  .intro__grid-item:nth-child(2n-1) {
    margin-right: 10px;
  } 
}
@media (max-width: 480px) {
}

/* ======================================= */
.intro-service {
  width: 100%;
}

.intro-service__con {
  width: 100%;
  height: 600px;
  display: flex;
}

.intro-sevice__con-left {
  width: 36%;
  background-color: gold;
  /* flex: 1; */
}

.intro-sevice__con-right {
  width: 64%;
  background-color: orange;
}

.intro-service__box01,
.intro-service__box02,
.intro-service__box03 {
  padding-top: 50px;
  /* grid 형태로 3분할 되어있어서, intro-service__con에 padding: 0 40px을 하면 배경까지 잘림. 따라서 따로 계산해둠 */
  padding-left: calc((100vw - 1400px)/2 + 40px);

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;  

  /* 텍스트 첫번째 줄 */
  font-size: 20px;
  font-weight: 500;
  color: #e870ac; 
  text-shadow: .5px .5px 1px rgba(255,255,255,0.8);

}

.intro-service__box02,
.intro-service__box03 {
  height: 50%;
}

.intro-service__box01 > div:nth-last-child(1),
.intro-service__box02 > div:nth-last-child(1),
.intro-service__box03 > div:nth-last-child(1) {
  padding-top: 10px;
  font-size: 28px;
  line-height: 30px;
  font-weight: 600;
  color: #fff; 
}

@media (max-width: 1400px) {
  .intro-service__box01,
  .intro-service__box02,
  .intro-service__box03 {
    padding: var(--page-padding-pc);
    padding-top: 50px;
  }
}

@media (max-width: 1023px) {
  .intro-service__con {
    height: auto;
    flex-direction: column;
  }

  .intro-sevice__con-left,
  .intro-sevice__con-right {
    width: 100%;
  }

  .intro-service__box01,
  .intro-service__box02,
  .intro-service__box03 {
    padding: var(--page-padding-tab);
    padding-top: 65px; /* 위 코드는 pdding-top, bottom을 0으로 만들어주니 따로 설정 */
    padding-bottom: 150px;

    font-size: 18px;
  }

  .intro-service__box01 > div:nth-last-child(1),
  .intro-service__box02 > div:nth-last-child(1),
  .intro-service__box03 > div:nth-last-child(1) {
    padding-top: 7px;
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  .intro-service__box01,
  .intro-service__box02,
  .intro-service__box03 {
    padding: var(--page-padding-mo);    
    padding-top: 65px;
    padding-bottom: 150px;
  }
}
@media (max-width: 480px) {
}






/* ======================================= */

.intro-review {
  padding: 100px 0;
}

.intro-review__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
} 

.intro-review__txt-title,
.intro-review__txt-sub {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #333;
}

.intro-review__txt-sub {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 400;
}

.intro-review__img-con {
  display: flex;
  margin: 0 -10px;
}

.intro-review__img-item {
  box-sizing: border-box;
  background-size: 105%;
  background-position: center;
  transition: background-size .3s ease;

  width: calc(25% - 20px);
  margin: 0 10px;
  position: relative;
}

.intro-review__img-item::after {
  content: '';
  display: block;
  padding-bottom: 135%;
}


.intro-review__img-item:hover {
  background-size: 120%;
}

.intro-review__img-txt-con {
  width: 80%;
  height: 50%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: white;
}

.intro-review__img-txt-sub {
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  text-align: left;
}

@media (max-width: 1023px) {
  .intro-review {
    padding-top: 64px;
  }

  .intro-review__con {
      padding: var(--page-padding-tab);
  }

  .intro-review__txt-sub {
    font-size: 18px;
  }  

  .intro-review__img-txt-con {
    width: 85%;
    top: 30%;
    font-size: 16px;
  }
  
  .intro-review__img-txt-sub {
    margin-top: 10px;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.5px;

  }
}

@media (max-width: 767px) {
  .intro-review__con {
    padding: var(--page-padding-mo);
  }

  .intro-review__img-con {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
  }

  .intro-review__img-item {
      width: calc(50% - 10px);
      margin: 5px;
  }
}

/* ======================================= */


.intro-contact {
  padding: 100px 0;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;  
}

.intro-contact__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
} 

.intro-contact__txt-title,
.intro-contact__txt-sub {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #333;
}

.intro-contact__txt-sub {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 400;
}



.intro-contact__input-con {
  padding: 30px 0 20px 0;
  margin: 0 auto;
  max-width: 700px;
  /* border-bottom: 1px solid #eee; */
}



.intro-contact__btn-submit {
  margin-top: 40px;
  height: 70px;
  width: 100%;
  border: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  color: white;
}

.chk-next {
  background: #006aff;;
}

@media (max-width: 1023px) {
  .intro-contact {
    padding: 65px 0;
  }

  .intro-contact__btn-submit {
    height: 50px;
    font-size: 18px;
  }
  
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
}

/* ========================================================= */
/* Footer */

.footer {
  padding: 20px 0;

  /* custom */
  background: black;
  color: lightgray;

}

.footer__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);

  display: flex;
  position: relative;
}

.footer__logo {
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: white;

  /* custom */
  font-weight: bold;
}

.footer__logo > img {
  height: 50px;
}

.footer__txt01 {
  padding-bottom: 10px;
  font-size: 13px;
  line-height: 24px;
}

.footer__txt02 {
  font-size: 16px;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

@media (max-width: 1023px) {
  .footer {
      padding-bottom: 50px;
  }
  .footer__con {
      padding: var(--page-padding-tab);
      flex-direction: column;
  }
  .footer__logo {
      margin-bottom: 15px;
      justify-content: left;
      font-size: 24px;
  }
  .footer__txt01 {
      font-size: 12px;
      line-height: 20px;
  }
  
  .footer__txt02 {
      font-size: 14px;
  }    
  .footer__law-con {
      right: 50%;
      transform: translateX(50%);
      bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footer__con {
      padding: var(--page-padding-mo);
  }
}
@media (max-width: 480px) {
}

/* ========================================================= */
.detail-page__banner-con {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;     
}

.detail-page__banner-con > div {
  padding-top: 240px;
  height: 100%;
  text-align: center;
  font-size: 45px;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 1023px) {
  .detail-page__banner-con {
    height: 250px;
  }

  .detail-page__banner-con > div {
    padding-top: 130px;
    font-size: 34px;
  } 
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
}

/* ========================================================= */

.detail-page__btn {
  height: 60px;
  background-color: #f7f4f4;
  border-bottom: 1px solid #e1dfdf;
}

.detail-page__btn-con {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: var(--page-width);
  display : flex;
}

.detail-page__btn-con > li > a {
  height: 100%;
  padding: 0 20px;
  border-left: 1px solid #e1dfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.detail-page__btn-menu,
.detail-page__btn-submenu {
  height: 60px;
  width: 250px;
  overflow: hidden;
}

.detail-page__btn-submenu {
  border-right: 1px solid #e1dfdf;
  z-index: 100;
}

.detail-page__btn-submenu span {
  color: #707070;
  font-weight: 800;
}

.detail-page__btn-submenu li > a {
  background-color: #f7f4f4;
  border: 1px solid #e1dfdf;
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 50px;
  font-weight: 400;
}

.detail-page__btn-submenu li:hover > a {
  background-color: #383433;
  color: #fff;
}

.detail-page__con {
  padding: var(--page-padding-pc);
  margin: 40px auto;
  width: 100%;
  max-width: var(--page-width);

  font-size: 15px;
  line-height: 30px;
  color: #707070;
}

.detail-page__title-con {
  margin: 80px auto;
} 

.detail-page__title-main,
.detail-page__title-sub {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #333;
}

.detail-page__title-sub {
  padding-top: 25px;
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 1023px) {
  .detail-page__con {
    padding: var(--page-padding-tab);
    margin: 50px auto;
  }

  .detail-page__title-con {
    margin: 0px auto;
  } 
  

  .detail-page__title-main {
    font-size: 30px;
  }

  .detail-page__title-sub {
    padding-top: 10px;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .detail-page__con {
    padding: var(--page-padding-mo);
  }
}


/* ========================================================= */

.introduction__logo-wrap {
  margin: 80px 0 20px 0;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: #111;
  text-shadow: 2px 2px 2px #afafaf;
}


.introduction__logo-wrap > img {
  width: 500px;
}

.introduction__txt {
  padding: 50px 0 40px 0;
}

@media (max-width: 767px) {
  .introduction__logo-wrap > img {
    width: 300px;
  }
}

/* ========================================================= */

.ourservice__img-con {
  display: flex;
  margin: 0 -10px;
}

.ourservice__img-item {
  box-sizing: border-box;
  background-size: 105%;
  background-position: center;
  transition: background-size .3s ease;

  width: calc(25% - 20px);
  margin: 0 10px;
  position: relative;
}

.ourservice__img-item::after {
  content: '';
  display: block;
  padding-bottom: 135%;
}


.ourservice__img-item:hover {
  background-size: 120%;
}

.ourservice__img-txt-con {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: white;
}

@media (max-width: 1023px) {
  .ourservice__img-txt-con {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .ourservice__img-con {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
  }

  .ourservice__img-item {
      width: calc(50% - 10px);
      margin: 5px;
  }
}

/* ========================================================= */

.service__con {
  display: flex;
  flex-direction: row;
}

.service__con > img {
  width: 35%;
  object-fit: cover;
  object-position: center;

}

.service__txt {
  margin-left: 50px;
}

@media (max-width: 767px) {
  .service__con {
    flex-direction: column;
  }

  .service__con > img {
    width: 100%;
    height: 300px;
  }
  
  .service__txt {
    margin-left: 0;
    margin-top: 20px;
  }
}


.service__header-con {
  margin-top: 100px;
  position: relative;
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -1px;
  color:#333;
}

.service__content-con {
  margin-top: 80px;
  display: flex;
  width: 100%;
}

.service__content-txt {
  width: 50%;
  /* padding-right: 5%; */
}

.service__content-txt-item {
  /* margin-bottom: 80px; */
  padding: 35px 0;
  opacity: 0.3;
  transition: opacity 0.3s;
  color: #333;
  border-bottom: 1px solid rgb(150,150,150, 0.3);
}

.opacity-1 {
  opacity: 1;
  border-bottom: 1px solid #111;

}

.opacity-point3 {
  opacity: 0.3;
  border-bottom: 1px solid rgb(150,150,150, 0.3);
}

.service__content-txt-title {
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -1px;
}

.service__content-txt-sub {
  margin-right: 100px;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -1px;
  word-break: keep-all;
}

.service__content-more > a {
  margin-top: 20px;
  display: inline-block;
  height: 100%;
  font-size: 25px;
  font-weight: 500;
  opacity: 0.1;
  cursor: pointer;
}

.service__content-img-con {
  position: relative;
  width: 50%;
  margin-top: -30px;
  height: 900px;
}

.service__triangle {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 0;
  height: 0;
  border-bottom: 65px solid transparent;
  border-top: 65px solid #fff;
  border-left: 65px solid #fff;
  border-right: 65px solid transparent;
  z-index: 100;
}

.service__content-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.service__content-img > img {
  position: absolute;
  left : 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.service__img-wrap-mo {
  display: none;
}

@media (max-width:834px) {
  .up--start-mo {
    opacity: 0;  
    transform: translateY(30px);
  }
  .up--end1-mo {
    opacity: 1 !important;  
    transform: translateY(0px);
    transition : all 0.5s;
  }
  .service__con {
    padding: var(--page-padding-mo);
  }
  .service__header-con {
    font-size: 25px;
    /* font-weight: 700; */
    line-height: 38px;
    /* letter-spacing: -1px; */
  }
  .service__content-con {
    margin-top: 20px;
    /* display: flex; */
    /* width: 100%; */
  }  
  .service__content-txt {
    width: 100%;
  }  
  .service__content-txt-item {
    /* opacity: 1 !important; */
    padding: 30px 0;
  }

  .service__content-txt-item:nth-child(4) {
    border-bottom: none;
  }

  .service__img-wrap-mo {
    display: block;
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }

  .service__img-wrap-mo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 2px 2px 2px #333;
  }

  .service__content-img-con {
    display: none;
  }
  .service__content-txt-title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -1px;
  }
  .service__content-txt-sub {
    margin-right: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    /* letter-spacing: -1px; */
    /* word-break: keep-all; */
  }
  .btn-more-static {
    margin-top: 5px;
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -1px;
    /* text-align: center; */
    text-align: left;
    cursor: pointer;
  }
}


/* ========================================================= */

.reason__con {
  text-align: center;
}

.img-it {
  width: 80%;
  margin-bottom: 40px;
  border-radius: 20px;
}

.reason__box-con {
  display: flex;
  margin: 0 -30px;
}

.reason__box-item {
  width: calc(33% - 120px);
  margin: 0 60px;
  text-align: left;
}

/* 하단의 텍스트임 */
.reason__box-txt01 {
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
  color: #006aff;
}

.reason__box-txt02 {
  padding-bottom: 20px;
  font-size: 25px;
  color: #111;
}

.reason__box-txt03 {
  font-size: 18px;
  line-height: 30px;
}

@media (max-width: 1023px) {
  .reason {
      padding: 64px 0;
  }
  .reason__txt01 {
      font-size: 16px;
      padding-bottom: 12px;
  }
  .reason__txt02 {
      padding-bottom: 30px;
      font-size: 22px;
      line-height: 38px;
  }
  .reason__box-item {
      width: calc(33% - 60px);
      margin: 0 30px;
      text-align: left;
  }

  .reason__box-txt01 {
      padding-bottom: 10px;
  }
  
  .reason__box-txt02 {
      padding-bottom: 10px;
      font-size: 24px;
  }
  
  .reason__box-txt03 {
      font-size: 16px;
      line-height: 26px;
  }    
}
@media (max-width: 767px) {
  .img-it {
      width: 100%;
      margin-bottom: 40px;
  }
  .reason__box-con {
      flex-direction: column;
      margin: 0;
      align-items: center;
  }
  .reason__box-item {
      width: 100%;
      max-width: 300px;
      margin: 0;
      padding-bottom: 40px;
  }
  
  

}
@media (max-width: 480px) {
}

/* ========================================================= */

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: #111; */
  background-image: linear-gradient(45deg, #1e1d1d 0%, #161515 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  /* transition: all 0.5s; */

  z-index: 99999
}

.loading-txt {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.5px;
  /* color: #266cad; */
  color: #fff;
  text-align: center;
}

.loading-after {
  opacity: 0;
  z-index: -99999;
}

  /* ========================================================= */

  .section-page__header-con {
    margin: 40px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: -0.5px;
    word-break: keep-all;
    text-align: center;
  }

  .license__con {
    padding: var(--page-padding-pc);
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-width);
  } 
  
  .license__grid-con { 
    /* margin: 40px 0 40px -10px; */
    display: flex;
    flex-wrap: wrap;
  }
  
  .license__grid-item {
    margin: 30px 10px 30px 10px;
    width: calc( (100% - 3 * 20px) / 3 );
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    position: relative;
  }
  
  .license__source-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #428ef9;
    cursor: pointer;
  }

  .license__source-url {
    letter-spacing: -1px;
    opacity: 0.7;
    font-weight: 400;
    color: darkblue;
    text-decoration: underline;
    word-break: break-all;
  }
  
  .license__source-type {
    letter-spacing: -1px;
    opacity: 0.7;
  }
  
  
  .license__source-writer {
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 22px;
  }
 
  
  .license__txt-item {
    padding: 30px 0;
    border-top: 1px solid #afafaf;
  }

  .license__txt-title {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }

  .license__txt-sub {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #222222;
  }
  
  @media (max-width: 1023px) {
    .license__con {
      padding: var(--page-padding-tab);
    } 
  
    .license__txt-sub {
      font-size: 18px;
    }
  
  }
  @media (max-width: 767px) {
    .license__con {
      padding: var(--page-padding-mo);
    }
  
    .license__grid-item {
      margin: 15px 0px 15px 0px;
      margin-bottom: 15px;
      width: calc( (100% - 2 * 10px) / 2 );
    }
  
    .license__grid-item:nth-child(2n-1) {
      margin-right: 20px;
    }
  
    .license__source-name {
      font-size: 18px;
    }
  
    
    .license__source-type {
      font-size: 15px;
    }
  
    .license__source-writer {
      font-size: 15px;
      font-weight: 400;
    }
  
   
  }
  @media (max-width: 480px) {
  }
  
  